import { CustomSelectOption } from "../components/CustomSelect";

export const EMPTY_NAME_ERROR_MESSAGE = "Enter your employer name to continue";

export const NAME_NOT_FOUND_ERROR_MESSAGE = "This organisation is not registered with CarbonWise\u2122, please check spelling and try again.";

export enum SURVEY_PAGES_INDEX {
	__START,
	EMAIL,
	MAIN_WORKPLACE,
	ADDRESS,
	COMMUTE,
	__END,
}

export const SURVEY_DETAILS_FETCH_ERROR = "There was an error fetching survey information. Try again later, or contact your organization for more information.";

export const ERROR_MESSAGE_DEFAULT = "An error has occurred. Please try again later.";
export const ERROR_MESSAGE_SESSION = "Your session has expired, please refresh the page and start again.";

/**
 * A workaround to get the length of Typescript Enum with flexible indexing.
 */
export const getTotalPage = (additionalPages = 0): number => SURVEY_PAGES_INDEX.__END - (SURVEY_PAGES_INDEX.__START + 1) + additionalPages;

/**
 * Button labels
 */
export const BUTTON_LABEL = {
	back: "< Back",
	next: "Next >",
	submit: "Submit",
};

export enum FetchStatus {
	PENDING = "PENDING",
	SUCCESS = "SUCCESS",
	FAILURE = "FAILURE",
	IDLE = "IDLE",
}

export enum SurveyPropertiesForSubmit {
	SurveyID = "SurveyID",
}

export enum WeekDay {
	MON = "Monday",
	TUE = "Tuesday",
	WED = "Wednesday",
	THU = "Thursday",
	FRI = "Friday",
	SAT = "Saturday",
	SUN = "Sunday",
}

export const WeekDayAbbreviation = {
	[WeekDay.MON]: "MON",
	[WeekDay.TUE]: "TUE",
	[WeekDay.WED]: "WED",
	[WeekDay.THU]: "THU",
	[WeekDay.FRI]: "FRI",
	[WeekDay.SAT]: "SAT",
	[WeekDay.SUN]: "SUN",
};

export enum CommuteDirection {
	TO_WORK = "toWork",
	FROM_WORK = "fromWork",
}

export enum CarTravelMode {
	CAR_BY_MYSELF = "Car by myself",
	CAR_WITH_SOMEONE = "Car with someone",
}

export interface ISelectMenuOption {
	name: string;
	value: string;
	iconPath?: string;
}

export const ADDRESS_PAGE_CHECKBOX_TEXT = "I don't want to share my address and understand that I won't see my calculated commuting emissions on survey completion.";

/**
 * A workplace option to be used at the commute page.
 * For submission, a null value should replace this value as the workplaceID.
 */
export const WORKPLACE_VALUE_WORK_FROM_HOME: CustomSelectOption = { name: "Work from home", value: "Work from home", index: 0 };

export const TRAVEL_MODE_VALUE_INITIAL = "";

/**
 * The number of visible text lines for the <textarea> HTML element in survey pages
 */
export const TEXTAREA_ROWS = 10;

/**
 * Number of maximum characters the free-typed answer can have
 */
export const MAX_CHARACTER_OF_ANSWERS = 1300;

export interface TextAreaProps {
	inputInvalid: boolean;
	characterCount: number;
	maxCharacter: number;
}

export interface CalculationResult {
	CO2e_kg: string;
	equivalentFlights: string;
	equivalentCarKms: string;
	workplaceInitiativesLink: string;
	tipsBlogLink: string;
	isEquivalentFlightPlural: boolean;
}

export const WIDER_SURVEY_CONTAINER_CLASS = "wider-col";

/**
 * A string to separate multiple options in a string. For example the comma in "Yes, No, Maybe".
 */
export const MULTI_OPTION_SEPARATOR = "|";

export const TOOLTIP_CONFIG = {
	emailPage: "Your email is used to contact you if you win a prize, and to prevent duplicate survey responses.",
	mainWorkplacePage: "",
	addressPage: "If you don't want to use your home address, you can enter your suburb or a street name.",
	commutePageHeading: `If you didn't work for any reason, use the tickbox and if you worked from home select "Working from home" in the workplace dropdown field.`,
	commutePagePrimaryMode: `This is the transportation you used for the longest distance.`,
	commutePageSecondaryMode: `This is the transportation you used for the second longest distance.`,
};

export const TOOLTIP_CLASS_NAME = {
	genericTooltipClassName: "lh-sm",
	travelModeTooltipClassName: "tooltip-travel-mode",
};

export const COMMUTE_MODE_LABEL = {
	primaryMode: "Primary mode",
	secondaryMode: "Secondary mode",
};

export const EXIT_MODAL_CONFIG = {
	title: "Are you sure you want to exit the survey?",
	companyPageBody: "The survey is a maximum of 5 questions and should take under 10 minutes. There is also a prize draw after you complete the survey. Do you still want to exit?",
	surveyPagesBody: "Your answers will not be saved, and you will need to start the survey from the beginning. Do you still want to exit?",
};
