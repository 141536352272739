import { createAction, createAsyncThunk, createSelector, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { ApplicationState } from ".";
import { CustomSelectOption } from "../components/CustomSelect";
import { FetchStatus, SurveyPropertiesForSubmit, WORKPLACE_VALUE_WORK_FROM_HOME } from "../constants/surveyConstants";
import { appAxios } from "../lib/appAxios";
import { logger } from "../lib/logApi";
import { IOptionalQuestion, IResponseDataAfterSubmission, ISurveyDetails, ISurveyIDData, ISurveyStateForSubmit, SurveyOnlineStatus, SurveyOnlineStatusResponse } from "./helpers/interfacesAndEnums";

const log = logger();

export interface ISurveyState {
	fetchStatus: FetchStatus;
	surveyID: string | null;
	csrfToken: string;
	surveyOnlineStatus: SurveyOnlineStatus | null;
	// The naming is to differentiate it from the fetchStatus above
	isFetchingSurveyOnlineStatus: boolean;
	errorFetchingSurveyOnlineStatus: boolean;
	surveyDetails: ISurveyDetails | null;
	responseAfterSubmission: IResponseDataAfterSubmission | null;
}

const initialState: ISurveyState = {
	fetchStatus: FetchStatus.IDLE,
	surveyID: null,
	csrfToken: "",
	surveyOnlineStatus: null,
	isFetchingSurveyOnlineStatus: true,
	errorFetchingSurveyOnlineStatus: false,
	surveyDetails: null,
	responseAfterSubmission: null,
};

export const resetUserData = createAction("resetUserData");

export const fetchSurvey = createAsyncThunk("surveySlice/fetchSurveyId", async (companyName: string): Promise<ISurveyIDData> => {
	const response = await appAxios.get(`/companies/match?name=${companyName}`, { withCredentials: true });
	const surveyIDData = response.data as ISurveyIDData;
	if (!(SurveyPropertiesForSubmit.SurveyID in surveyIDData)) {
		throw new Error("surveySlice/fetchSurveyId: No SurveyID found in response data");
	}
	if (!surveyIDData.SurveyID) {
		throw new Error("surveySlice/fetchSurveyId: Empty SurveyID found in response data");
	}
	return surveyIDData;
});

const flagErrorFetchingSurveyOnlineStatus = createAction<boolean>("cards/flagErrorFetchingSurveyOnlineStatus");

export const fetchSurveyOnlineStatus = createAsyncThunk("surveySlice/fetchSurveyOnlineStatus", async (_, { dispatch }): Promise<SurveyOnlineStatus | null> => {
	dispatch(flagErrorFetchingSurveyOnlineStatus(false));
	try {
		const response = await appAxios.get("/surveys/status");
		const statusData = response.data as SurveyOnlineStatusResponse;
		if (!("status" in statusData)) {
			throw new Error("No survey status found in response data");
		}
		return statusData.status;
	} catch (err) {
		log.error(err);
		dispatch(flagErrorFetchingSurveyOnlineStatus(true));
		return null;
	}
});

export const fetchSurveyDetails = createAsyncThunk("surveySlice/fetchSurveyDetail", async (surveyId: string, { getState }): Promise<ISurveyDetails> => {
	const csrfToken = (getState() as ApplicationState).surveyState.csrfToken;
	const response = await appAxios.get(`/surveys/${surveyId}/details`, { headers: { "x-csrf-token": csrfToken }, withCredentials: true });
	const surveyDetailsData = response.data as ISurveyDetails;
	return surveyDetailsData;
});

export const submitSurvey = createAsyncThunk("surveySlice/submitSurvey", async (dataToSubmit: ISurveyStateForSubmit, { getState }): Promise<IResponseDataAfterSubmission> => {
	const csrfToken = (getState() as ApplicationState).surveyState.csrfToken;
	const response = await appAxios.post("/responses", dataToSubmit, { headers: { "x-csrf-token": csrfToken }, withCredentials: true });
	return response.data as IResponseDataAfterSubmission;
});

const statusPending = isAnyOf(fetchSurvey.pending, fetchSurveyDetails.pending, submitSurvey.pending);
const statusRejected = isAnyOf(fetchSurvey.rejected, fetchSurveyDetails.rejected, submitSurvey.rejected);

const surveySlice = createSlice({
	name: "surveySlice",
	initialState,
	reducers: {
		resetFetchStatus: (state) => {
			state.fetchStatus = initialState.fetchStatus;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchSurvey.fulfilled, (state, action) => {
				state.fetchStatus = FetchStatus.SUCCESS;
				if (!action.payload) return;
				state.surveyID = action.payload.SurveyID;
				state.csrfToken = action.payload["x-csrf-token"];
			})
			.addCase(fetchSurveyDetails.fulfilled, (state, action) => {
				state.fetchStatus = FetchStatus.SUCCESS;
				if (!action.payload) return;
				state.surveyDetails = action.payload;
			})
			.addCase(fetchSurveyOnlineStatus.pending, (state) => {
				state.isFetchingSurveyOnlineStatus = true;
			})
			.addCase(fetchSurveyOnlineStatus.fulfilled, (state, action) => {
				state.isFetchingSurveyOnlineStatus = false;
				if (!action.payload) return;
				state.surveyOnlineStatus = action.payload;
			})
			.addCase(fetchSurveyOnlineStatus.rejected, (state) => {
				state.isFetchingSurveyOnlineStatus = false;
			})
			.addCase(flagErrorFetchingSurveyOnlineStatus, (state, action) => {
				state.errorFetchingSurveyOnlineStatus = action.payload;
			})
			.addCase(submitSurvey.fulfilled, (state, action) => {
				state.fetchStatus = FetchStatus.SUCCESS;
				if (!action.payload) return;
				state.responseAfterSubmission = action.payload;
			})
			.addMatcher(statusPending, (state) => {
				state.fetchStatus = FetchStatus.PENDING;
			})
			.addMatcher(statusRejected, (state) => {
				state.fetchStatus = FetchStatus.FAILURE;
			});
	},
});

export const selectSurveyID = (state: ApplicationState): string | null => state.surveyState.surveyID;
export const selectSurveyFetchStatus = (state: ApplicationState): string | null => state.surveyState.fetchStatus;
export const selectSurveyDetails = (state: ApplicationState): ISurveyDetails | null => state.surveyState.surveyDetails;
const selectSurveyWorkplaces = (state: ApplicationState) => state.surveyState.surveyDetails?.workplaces;
export const selectSurveyOnlineStatus = (state: ApplicationState): SurveyOnlineStatus | null => state.surveyState.surveyOnlineStatus;
export const selectErrorFetchingSurveyOnlineStatus = (state: ApplicationState): boolean => state.surveyState.errorFetchingSurveyOnlineStatus;
export const selectWaitingForFetchingSurveyOnlineStatus = (state: ApplicationState): boolean => state.surveyState.isFetchingSurveyOnlineStatus;

export const selectSurveyFetchReady = createSelector(selectSurveyID, selectSurveyDetails, selectSurveyFetchStatus, (id, details, status): boolean => {
	return !!id && !!details && status === FetchStatus.SUCCESS;
});
export const selectSurveyTitle = createSelector(selectSurveyDetails, (details): string | undefined => {
	return details?.survey.SurveyTitle;
});
export const selectSurveyPrizeDrawDescription = createSelector(selectSurveyDetails, (details): string | undefined => {
	return details?.survey.PrizeDrawDescription;
});
export const selectSurveyInitiativesLink = createSelector(selectSurveyDetails, (details): string | undefined => {
	return details?.survey.WorkplaceInitiativesLink;
});
export const selectSurveyCountryCodes = createSelector(selectSurveyDetails, (details): string | undefined => {
	return details?.survey.CountryCodes;
});

// The workplace index starts from 10, to leave some room for predefined options such as "Work from home".
export const WORKPLACE_OPTION_START_INDEX = 10;

export const selectNonRemoteWorkplaceOptions = createSelector(selectSurveyWorkplaces, (workplaces): CustomSelectOption[] | undefined => {
	if (!workplaces || !workplaces.length) {
		log.error("No workplaces found");
		return;
	}
	const nonRemoteWorkplaces = workplaces.filter((w) => !w.IsRemoteWorkplace);

	return nonRemoteWorkplaces.map((place, index) => ({
		name: place.Label,
		value: place.UniqueID,
		index: index + WORKPLACE_OPTION_START_INDEX,
	}));
});

/**
 * Output an array of name-value pairs to match react-select-search select option.
 */
export const selectAllWorkplaceOptions = createSelector(selectSurveyWorkplaces, (workplaces): CustomSelectOption[] | undefined => {
	if (!workplaces || !workplaces.length) {
		log.error("No workplaces found");
		return;
	}
	return workplaces.map((place, index) => ({
		name: place.Label,
		value: place.UniqueID,
		index: index + WORKPLACE_OPTION_START_INDEX,
	}));
});

/**
 * Get a list of workplaces (offices), with remote working option removed and "Working from home" option added.
 * 'Working from home' appears at the top of the list
 */
export const selectCommuteWorkplaces = createSelector(selectNonRemoteWorkplaceOptions, (workplaces): CustomSelectOption[] | undefined => {
	if (!workplaces) return;

	return [WORKPLACE_VALUE_WORK_FROM_HOME, ...workplaces];
});

/**
 * Output an array of name-value pairs to match react-select-search select option.
 */
export const selectTravelModesInNameValuePairs = createSelector(selectSurveyDetails, (details): CustomSelectOption[] => {
	if (!details || !details.modes || !details.modes.length) {
		log.error("No travel modes found");
		return [];
	}
	return details.modes.map((mode, index) => ({
		name: mode.Label,
		value: mode.UniqueID,
		index: index,
	}));
});

/**
 * Output an array of string IDs whose label contains "car" or "Car"
 */
export const selectCarTravelModesAsIDs = createSelector(selectTravelModesInNameValuePairs, (modes): string[] | undefined => {
	if (!modes || !modes.length) {
		log.error("No travel modes found");
		return;
	}

	const regex = /car/i;
	const cardModes = modes.filter((m) => regex.test(m.name));
	if (!cardModes.length) {
		log.error("No car modes found");
		return;
	}
	const cardModesIDs = cardModes.map((m) => m.value as string);
	return cardModesIDs;
});

/**
 * Output an array of name-value pairs to match react-select-search select option.
 */
export const selectVehicleFuelTypesInNameValuePairs = createSelector(selectSurveyDetails, (details): CustomSelectOption[] | undefined => {
	if (!details || !details.vehicleTypes || !details.vehicleTypes.length) {
		log.error("No vehicle types found");
		return;
	}
	return details.vehicleTypes.map((vehicleType, index) => ({
		name: vehicleType.Label,
		value: vehicleType.UniqueID,
		index: index,
	}));
});

export const selectOptionalQuestions = createSelector(selectSurveyDetails, (details): IOptionalQuestion[] | undefined => {
	return details?.optionalQuestions;
});

export const selectOptionalQuestionAmount = createSelector(selectOptionalQuestions, (questions): number => {
	return questions ? questions.length : 0;
});

export const selectResponseAfterSubmission = (state: ApplicationState): IResponseDataAfterSubmission | null => state.surveyState.responseAfterSubmission;

export const { resetFetchStatus } = surveySlice.actions;

export const reducer = surveySlice.reducer;
